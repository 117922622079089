import React from "react"
import Img from "gatsby-image"
import GalleryHeader from "./GalleryHeader"
import BgImg from "../BgImg"

const GalleryBase = ({ images, title }) => {
  return (
    <>
      <div style={{ zIndex: 10 }}>
        <GalleryHeader title={title} />
        <div className="gallery">
          {images.map(({ fluid }, index) => (
            <Img key={index} fluid={fluid} style={{ width: "500px" }} />
          ))}
        </div>
      </div>
      <BgImg />
    </>
  )
}

export default GalleryBase
